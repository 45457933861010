import { axios } from '../lib/axios';
import {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse,
    IAutopilotAdsItem,
    GetAutopilot2ListParams,
    CreateAutopilot2Params,
    UpdateAutopilot2Params,
    UpdateAutopilot2BriefParams,
    UpdateAutopilot2MediaplanSettingsParams,
    UpdateAutopilot2BudgetParams,
    UpdateAutopilot2SocialParams,
    UpdateAutopilot2ContextParams,
    UpdateAutopilot2ProgrammaticParams,
    Autopilot2MediaplanChannelsGroups,
    UpdateAutopilot2DashboardParams,
    UpdateAutopilot2PlacementParams,
    LaunchAutopilotCampaignParams,
    StopAutopilotCampaignParams,
    TypeTradeOffer,
    TypeResponseAutopilotGenerate,
    TypeChatGPT,
    ILPlacementSpec,
    TypeCreative,
    CreativeFile,
    TypeRefrenceItem,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/autopilot/v2';

export class AutopilotApi {
    public static async getAutopilot(autopilotId: string): Promise<Autopilot2> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(autopilotId: string): Promise<Autopilot2MediaplanPlanDataResponse> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async getVariationsProduct({
        autopilotId,
        productId,
    }: {
        autopilotId: string;
        productId: string;
    }): Promise<TypeTradeOffer> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/products/${productId}`);
        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilot2ListParams): Promise<Autopilot2[]> {
        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async generateAds({
        autopilotId,
        productId,
        params,
    }: {
        autopilotId: string;
        productId: string;
        params: TypeChatGPT;
    }): Promise<TypeResponseAutopilotGenerate> {
        const res = await axios.post(`${BASE_URL}/${autopilotId}/ads/${productId}`, params);
        return res.data;
    }

    public static async getMediaplanXLS(autopilotId: string): Promise<Blob> {
        const res = await axios.get<Blob>(`${BASE_URL}/${autopilotId}/mediaplan-xls`, { responseType: 'blob' });

        return res.data;
    }

    public static async createAutopilot(params: CreateAutopilot2Params): Promise<void> {
        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(autopilotId: string, params: UpdateAutopilot2Params): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(autopilotId: string, params: UpdateAutopilot2BriefParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplanSettings(
        autopilotId: string,
        params: UpdateAutopilot2MediaplanSettingsParams,
    ): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan-settings`, params);
    }

    public static async updateMediaplan(autopilotId: string): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`);
    }

    public static async updateBudget(autopilotId: string, params: UpdateAutopilot2BudgetParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async updateSocial(autopilotId: string, params: UpdateAutopilot2SocialParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/social`, params);
    }

    public static async updateContext(autopilotId: string, params: UpdateAutopilot2ContextParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/context`, params);
    }

    public static async updateCreative(autopilotId: string, params: TypeCreative): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/creative`, params);
    }

    public static async saveAds(autopilotId: string, params: IAutopilotAdsItem[]): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/ads`, params);
    }

    public static async updateProgrammatic(
        autopilotId: string,
        params: UpdateAutopilot2ProgrammaticParams,
    ): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/programmatic`, params);
    }

    public static async getChannelsGroups(autopilotId: string): Promise<Autopilot2MediaplanChannelsGroups> {
        const res = await axios.get<Autopilot2MediaplanChannelsGroups>(
            `${BASE_URL}/${autopilotId}/mediaplan-channels-groups`,
        );

        return res.data;
    }

    public static async getSpecReferences(refId: string): Promise<TypeRefrenceItem[]> {
        const res = await axios.get<TypeRefrenceItem[]>(`${BASE_URL}/creative/${refId}/references`);
        return res.data;
    }

    public static async getDownloadLink(fileId: string): Promise<void> {
        await axios.post<void>(`${BASE_URL}/file/${fileId}`);
    }

    public static async getCreativeSpecs(specId: string): Promise<ILPlacementSpec> {
        const res = await axios.get<ILPlacementSpec>(`${BASE_URL}/creative/${specId}/specs`);
        return res.data;
    }

    public static async updateDashboard(autopilotId: string, params: UpdateAutopilot2DashboardParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/dashboard`, params);
    }

    public static async updatePlacement(autopilotId: string, params: UpdateAutopilot2PlacementParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/placement`, params);
    }

    public static async addCreativeFile(autopilotId: string, fileId: string, params: CreativeFile): Promise<void> {
        await axios.post<void>(`${BASE_URL}/${autopilotId}/file/${fileId}`, params);
    }

    public static async launch(autopilotId: string, params: LaunchAutopilotCampaignParams): Promise<void> {
        await axios.post<void>(`${BASE_URL}/${autopilotId}/launch`, params);
    }

    public static async stop(autopilotId: string, params?: StopAutopilotCampaignParams): Promise<void> {
        await axios.post<void>(`${BASE_URL}/${autopilotId}/stop`, params);
    }
}
