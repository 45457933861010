import { actionCreatorFactory } from 'typescript-fsa';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, AutopilotPlacementPlanData } from 'sber-marketing-types/backend';
import type { BriefStageForm, CreativeStageForm, GroupedDictionaries, SwitchKeyActivityParams } from './types';

const actionCreator = actionCreatorFactory('AUTOPILOT_PAGE_LEGACY');

const loadAutopilot = actionCreator<Autopilot>('LOAD_AUTOPILOT');
const loadActivity = actionCreator<Activity>('LOAD_ACTIVITY');
const loadMediaplan = actionCreator<AutopilotPlacementPlanData[]>('LOAD_MEDIAPLAN');
const loadDictionaries = actionCreator<GroupedDictionaries>('LOAD_DICTIONARIES');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setBriefFormValues = actionCreator<Partial<BriefStageForm>>('SET_BRIEF_FORM_VALUES');
const setSelectedBudgetItemIds = actionCreator<string[]>('SET_SELECTED_BUDGET_ITEM_IDS');
const setCreativeFormValues = actionCreator<Partial<CreativeStageForm>>('SET_CREATIVE_FORM_VALUES');
const setMediaplanStatus = actionCreator<boolean>('SET_MEDIAPLAN_STATUS');

const switchKeyActivityAsync = actionCreator.async<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');

export {
    loadAutopilot,
    loadActivity,
    loadMediaplan,
    loadDictionaries,
    resetPageState,
    setBriefFormValues,
    setSelectedBudgetItemIds,
    setCreativeFormValues,
    setMediaplanStatus,
    switchKeyActivityAsync,
};
