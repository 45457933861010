import * as ExcelJS from 'exceljs';
import * as lodash from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { NotificationType, NotificationActionType } from '@store/common/types';
import { setNotification } from '@store/common/actions';
import {
    ColumnName as BudgetExecutionColumnNames,
    UnsavedChange,
    setPreloaderStatus,
    resetFilters,
    getBudgetExecutionPageState,
    applyChangesToChangeList,
    getColumnByMonth,
    initUnsavedChanges,
    setFilters,
    setFiltersState,
    loadFiltersByColumn,
} from '@store/budgetExecution';

import { LayerManager } from '@modules/budget/BudgetPage/BudgetExecution/Table/LayerManager';

import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';
import {
    InitCorrectionsPayload,
    LoadFMPTableResult,
    ColumnNames,
    ExcelColumnsToColumnNamesMap,
    WorkbookDescriptor,
    FetchedData,
    LoadFMPTablePayload,
    BudgetItemsStore,
    ColumnNamesToExcelColumnsMap,
} from './types';
import { getImportFMPTableMenuState } from './selectors';

const MAX_EXCEL_ROWS_TO_PARSE = 500;

export const loadFMPTable = bindThunkAction<StoreState, LoadFMPTablePayload, LoadFMPTableResult, Error>(
    asyncActions.loadFMPTable,
    async (paylaod, dispatch, getState) => {
        const { file, budgetId } = paylaod;

        const workbook = await loadFileContent(file);
        const workbookDescriptor = await generateWorkbookDescriptor(file, workbook);
        const fetchedData = fetchData(workbook, workbookDescriptor);
        const budgetItems = await getBudgetItems(budgetId, fetchedData);

        if (fetchedData.length !== budgetItems.entities.length) {
            throw new Error(
                `В загружаемом файле обнаружены несуществующие строки. Проверьте документ на корректность.`,
            );
        }

        return {
            workbookDescriptor,
            fetchedData,
            budgetItems,
        };
    },
);

export const initCorrections = bindThunkAction<StoreState, InitCorrectionsPayload, void, Error>(
    asyncActions.initCorrections,
    async (payload, dispatch, getState) => {
        const { applyTableFilters } = payload;

        const state = getState();
        const { unsavedChanges } = getBudgetExecutionPageState(state);
        const { fetchedData, budgetItems, targetMonth } = getImportFMPTableMenuState(state);

        const layerManager = LayerManager.getInstance();

        dispatch(setPreloaderStatus(true));
        dispatch(resetFilters());

        const updatedFilters = fetchedData.reduce((acc, data) => {
            acc[data[ColumnNames.SerialNumber]] = true;

            return acc;
        }, {});

        dispatch(
            setFilters({
                [BudgetExecutionColumnNames.Id]: updatedFilters,
            }),
        );
        dispatch(
            setFiltersState({
                [BudgetExecutionColumnNames.Id]: updatedFilters,
            }),
        );
        dispatch(loadFiltersByColumn(BudgetExecutionColumnNames.Id));

        await applyTableFilters();

        const unsavedChangesToAdd = fetchedData.reduce((acc, data) => {
            const budgetItem = budgetItems.byId[data[ColumnNames.SerialNumber]];

            if (budgetItem) {
                const lineCellParams = layerManager.getCellsParams()[budgetItem.id];
                const factTargetColumn = getColumnByMonth(targetMonth, 'fact');

                const changeValue =
                    (+(lineCellParams[factTargetColumn].originalValue || 0) + data[ColumnNames.FactValue]) / 100;
                const changeValueFormatted = `${parseFloat(changeValue.toFixed(2))}`;

                acc.push({
                    budgetItemId: budgetItem.id,
                    columnName: factTargetColumn,
                    value: changeValueFormatted,
                    originalValue: lineCellParams[factTargetColumn].originalValue,
                });

                if (data[ColumnNames.ZNS]) {
                    acc.push({
                        budgetItemId: budgetItem.id,
                        columnName: BudgetExecutionColumnNames.SapZns,
                        value:
                            budgetItem.sapZns && data[ColumnNames.ZNS]
                                ? `${budgetItem.sapZns} ${data[ColumnNames.ZNS]}`
                                : data[ColumnNames.ZNS] || budgetItem.sapZns || null,
                        originalValue: lineCellParams[BudgetExecutionColumnNames.SapZns].originalValue,
                    });
                }
            }

            return acc;
        }, [] as UnsavedChange[]);

        const updatedUnsavedChanges = applyChangesToChangeList(lodash.cloneDeep(unsavedChanges), unsavedChangesToAdd);

        dispatch(initUnsavedChanges(updatedUnsavedChanges));

        dispatch(setPreloaderStatus(false));

        dispatch(
            setNotification({
                type: NotificationType.SUCCESS,
                typeAction: NotificationActionType.FMP_TABLE_WAS_EXPORTED,
                comment: 'Таблица ФМП была успешно загружена',
            }),
        );
        dispatch(syncActions.resetComponentState());
    },
);

function loadFileContent(file: File): Promise<ExcelJS.Workbook> {
    const workbook = new ExcelJS.Workbook();
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

    return new Promise<ExcelJS.Workbook>((resolve) => {
        fileReader.onloadend = async () => {
            await workbook.xlsx.load(fileReader.result as Buffer);
            resolve(workbook);
        };
    });
}

function generateWorkbookDescriptor(file: File, workbook: ExcelJS.Workbook): WorkbookDescriptor {
    const worksheet = workbook.worksheets[0];
    const MAX_ROW_INDEX = Math.min(worksheet.rowCount, MAX_EXCEL_ROWS_TO_PARSE);

    const result: WorkbookDescriptor = {
        file,
        columns: {
            [ColumnNames.SerialNumber]: '',
            [ColumnNames.FactValue]: '',
            [ColumnNames.ZNS]: '',
        },
        dataStart: 0,
        dataCount: 0,
    };

    let r = 0;
    // 1. filling dataHeader
    // 2. dataStart will be next row after dataHeader
    for (; r !== MAX_ROW_INDEX; r++) {
        const row = worksheet.getRow(r);
        let dataHeaderWasFound = false;

        row.eachCell((cell) => {
            const columnDescriptor = ExcelColumnsToColumnNamesMap[(cell.value as string).replace(/\n/g, '')];

            if (columnDescriptor) {
                dataHeaderWasFound = true;
                result.columns[columnDescriptor] = cell.address.match(/[a-z]/gi).join();
            }
        });

        if (dataHeaderWasFound) {
            result.dataStart = row.number + 1;
            r++;
            break;
        }
    }

    // 3. searching for data rows to end
    for (; r !== MAX_ROW_INDEX; r++) {
        const row = worksheet.getRow(r);
        let rowHasData = false;

        row.eachCell((cell) => {
            const cellAdress = cell.address[0];
            const columnMatch =
                result.columns[ColumnNames.ZNS] === cellAdress ||
                result.columns[ColumnNames.FactValue] === cellAdress ||
                result.columns[ColumnNames.SerialNumber] === cellAdress;

            rowHasData = rowHasData || (columnMatch && !!cell.value);
        });

        if (!rowHasData) {
            result.dataCount = row.number - result.dataStart;
            break;
        }
    }

    const generatedDescriptorIsValid =
        result.dataStart && result.dataCount && Object.values(result.columns).every((key) => key);

    if (!generatedDescriptorIsValid) {
        throw new Error(`Неверный формат файла ФМП`);
    }

    return result;
}

function fetchData(workbook: ExcelJS.Workbook, descriptor: WorkbookDescriptor): FetchedData[] {
    const rows = workbook.worksheets[0].getRows(descriptor.dataStart, descriptor.dataCount);

    const dataGroupedById = lodash.groupBy(
        rows.map((row): FetchedData => {
            const serialNumber = row.getCell(descriptor.columns.ID).value;
            const zns = row.getCell(descriptor.columns.ZNS).value;
            const factCell = row.getCell(descriptor.columns.FactValue);
            const factValue = factCell.type === ExcelJS.ValueType.Formula ? factCell.result : factCell.value;

            return {
                [ColumnNames.SerialNumber]: serialNumber ? +serialNumber : null,
                [ColumnNames.FactValue]: factValue ? +((factValue as number) * 100).toFixed(0) : null,
                [ColumnNames.ZNS]: zns ? zns.toString() : null,
                rowsWithSameIdCount: 1,
            };
        }),
        (data) => data[ColumnNames.SerialNumber],
    );

    return Object.keys(dataGroupedById).reduce((acc, dataKey) => {
        let dataItemToAdd: FetchedData;
        if (dataGroupedById[dataKey].length === 1) {
            dataItemToAdd = dataGroupedById[dataKey][0];
        } else {
            const znsValuesSet = new Set(dataGroupedById[dataKey].map((data) => data[ColumnNames.ZNS]));

            dataItemToAdd = {
                [ColumnNames.SerialNumber]: dataGroupedById[dataKey][0][ColumnNames.SerialNumber],
                [ColumnNames.FactValue]: +lodash
                    .sum(dataGroupedById[dataKey].map((data) => data[ColumnNames.FactValue]))
                    .toFixed(0),
                [ColumnNames.ZNS]: [...znsValuesSet.values()].join(', '),
                rowsWithSameIdCount: dataGroupedById[dataKey].length,
            };
        }

        acc.push(dataItemToAdd);

        return acc;
    }, [] as FetchedData[]);
}

async function getBudgetItems(budgetId: string, fetchedData: FetchedData[]): Promise<BudgetItemsStore> {
    const entities = await BudgetItemApi.getBudgetItemList({
        budgetId,
        filter: {
            serialNumber: fetchedData.map((data) => data[ColumnNames.SerialNumber]),
        },
    });

    return {
        entities,
        byId: entities.reduce((acc, budgetItem) => {
            acc[budgetItem.serialNumber] = budgetItem;

            return acc;
        }, {}),
    };
}

export function getColumnDescriptor(cellValue: string): ColumnNames {
    const serialNumberRegExp = new RegExp(ColumnNamesToExcelColumnsMap[ColumnNames.SerialNumber]);
    const factValueRegExp = new RegExp(ColumnNamesToExcelColumnsMap[ColumnNames.FactValue]);
    const znsRegExp = new RegExp(ColumnNamesToExcelColumnsMap[ColumnNames.ZNS]);

    if (cellValue.match(serialNumberRegExp)) {
        return ColumnNames.SerialNumber;
    }

    if (cellValue.match(factValueRegExp)) {
        return ColumnNames.FactValue;
    }

    if (cellValue.match(znsRegExp)) {
        return ColumnNames.ZNS;
    }

    return null;
}
