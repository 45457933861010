import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import { CreativeUserConfigState, CreativeUserConfig, LoadCreativeUserConfig, UpdateCreativeUserConfig } from './types';
import { Tabs } from './enums';
import * as actions from './actions';

class Reducer {
    public static makeInitialState(): CreativeUserConfigState {
        return {
            status: LoadingStatus.NOT_LOADED,
            fields: {
                sidebar: {
                    visibility: false,
                    selectedTab: Tabs.Comments,
                },
                tableColumns: null,
                enabledColumns: null,
                columnsWidth: null,
                lineHeights: null,
                archivedFilterIsActive: false,
                budgetItemsModalParams: {
                    columns: null,
                    columnsWidth: null,
                },
            },
        };
    }

    public static loadUserConfigStarted(state: CreativeUserConfigState): CreativeUserConfigState {
        return {
            ...state,
            status: LoadingStatus.LOADING,
        };
    }

    public static loadUserConfigDone(
        state: CreativeUserConfigState,
        payload: Success<CreativeUserConfig, LoadCreativeUserConfig>,
    ): CreativeUserConfigState {
        const {
            result: { receivedUserConfig },
        } = payload;

        return {
            status: LoadingStatus.LOADED,
            fields: {
                ...state.fields,
                ...receivedUserConfig,
            },
        };
    }

    public static updateUserConfig(
        state: CreativeUserConfigState,
        payload: UpdateCreativeUserConfig,
    ): CreativeUserConfigState {
        return {
            ...state,
            fields: {
                ...state.fields,
                ...payload,
                sidebar: {
                    ...state.fields.sidebar,
                    ...payload.sidebar,
                },
            },
        };
    }
}

export const creativeUserConfigReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.loadCreativeUserConfig.started, Reducer.loadUserConfigStarted)
    .case(actions.loadCreativeUserConfig.done, Reducer.loadUserConfigDone)
    .case(actions.updateCreativeUserConfig.started, Reducer.updateUserConfig);
