import * as React from 'react';

import { Avatar, Card, CardProps, Description, Details, Flex, Icon, Text } from '@sbermarketing/mrm-ui';

import { OnboardingGroup } from '@modules/onboarding/constants';

import { OnboardingItemChip } from '../OnboardingItemChip';
import { useGetAuthUserQuery } from '@api';

export interface OnboardingGroupCardProps extends CardProps {
    onboardingGroup: OnboardingGroup;
}

export function OnboardingGroupCard({
    onboardingGroup: { icon, title, sections, description, module },
    ...props
}: OnboardingGroupCardProps) {
    const { data: authUser } = useGetAuthUserQuery();

    if (module && !authUser?.user.attributes.modules.includes(module)) return null;

    return (
        <Card radius={16} padding={24} {...props}>
            <Details
                gap={24}
                flex
                defaultOpen
                stretch
                summary={
                    <Flex align="center" flex gap={12}>
                        <Avatar size={48}>
                            <Icon icon={icon} />
                        </Avatar>
                        <Flex vertical>
                            <Text color="var(--color-gray-100)" size={16} weight="normal">
                                {title}
                            </Text>
                            <Description size={14} weight="normal">
                                {description}
                            </Description>
                        </Flex>
                    </Flex>
                }
            >
                <Flex gap={12} vertical>
                    {sections.map((item) => (
                        <OnboardingItemChip item={item} key={item.id} />
                    ))}
                </Flex>
            </Details>
        </Card>
    );
}
