import * as lodash from 'lodash';
import { DictionaryType } from '@mrm/dictionary';

import {
    REGIONALITY_BASED_DICTIONARIES,
    BLOCK_BASED_DICTIONARIES,
    GroupedDictionaries,
    DictionariesByType,
    DictionaryValue,
    isMainRegionalitySelected,
} from './common';

import { DictionariesFilter } from './DictionariesFilter';

export class MultiReferenceDictionaryApi {
    private dictionaries: GroupedDictionaries;
    private dictionariesFilter: DictionariesFilter;

    public useMultireferenceLogic(): boolean {
        return this.dictionariesFilter.useMultireferenceLogic;
    }

    public performDictionaryUpdate(
        oldDictionaryIds: string[],
        dictionaryType: DictionaryType,
        newDictionaryValue: string,
    ): DictionaryValue {
        const { byId: dictionariesById } = this.dictionaries;

        let value = this.makeDictionaryValue(oldDictionaryIds);
        if (newDictionaryValue) {
            value[dictionaryType] = dictionariesById[newDictionaryValue];
        } else {
            this.deleteDictionaryFromValue(value, dictionaryType);
        }

        return this.validateValue(value);
    }

    public getDictionariesForValue(value: DictionaryValue, dictionaryType: DictionaryType): DictionariesByType {
        return this.dictionariesFilter.filterDictionariesByValue({ ...value, [dictionaryType]: null }, dictionaryType);
    }

    public init(dictionaries: GroupedDictionaries) {
        this.dictionaries = dictionaries;
        this.dictionariesFilter = new DictionariesFilter(dictionaries);
    }

    public makeDictionaryValue(dictionaryIds: string[]): DictionaryValue {
        return this.validateValue(
            dictionaryIds.reduce((acc, dictionaryId) => {
                const dictionary = this.dictionaries.byId[dictionaryId] || null;

                if (dictionaryId && !dictionary) {
                    console.warn(`Missing dictionary with id ${dictionaryId}`);
                    return acc;
                }

                return {
                    ...acc,
                    [dictionary.type]: dictionary,
                };
            }, {} as DictionaryValue),
        );
    }

    public dictionaryIsRegionalityBased(dictionaryType: DictionaryType): boolean {
        return REGIONALITY_BASED_DICTIONARIES.includes(dictionaryType);
    }

    public dictionaryIsBlockBased(dictionaryType: DictionaryType): boolean {
        return BLOCK_BASED_DICTIONARIES.includes(dictionaryType);
    }

    // Current rules of validation
    // 1. If values alows only single dictionary with some DictionaryType, it must be autoselected
    public validateValue(value: DictionaryValue): DictionaryValue {
        const visibleDictionaries = this.dictionariesFilter.filterDictionariesByValue(value);

        const keysToCheck = lodash.uniq([
            ...Object.keys(visibleDictionaries),
            ...Object.keys(value),
        ] as DictionaryType[]);

        keysToCheck.forEach((dictionaryType: DictionaryType) => {
            const itemsByType = visibleDictionaries[dictionaryType];
            if (!itemsByType?.length) {
                value[dictionaryType] = null;
            } else if (itemsByType.length === 1) {
                value[dictionaryType] = itemsByType[0];
            }
        });

        return value;
    }

    private deleteDictionaryFromValue(value: DictionaryValue, dictionaryToDelete: DictionaryType): DictionaryValue {
        const mainRegionalityIsSelected = isMainRegionalitySelected(value);
        const tryingToDeleteRegionalityBasedValue = REGIONALITY_BASED_DICTIONARIES.includes(dictionaryToDelete);
        const tryingToDeleteBlockBasedValue = BLOCK_BASED_DICTIONARIES.includes(dictionaryToDelete);

        if (tryingToDeleteBlockBasedValue) {
            delete value[DictionaryType.Block];
            delete value[DictionaryType.Channel];
            delete value[DictionaryType.Segment];
            delete value[DictionaryType.Product];

            if (mainRegionalityIsSelected) {
                delete value[DictionaryType.Division];
            }
        } else if (tryingToDeleteRegionalityBasedValue) {
            if (dictionaryToDelete === DictionaryType.Regionality) {
                REGIONALITY_BASED_DICTIONARIES.forEach((dictionaryType) => {
                    delete value[dictionaryType];
                });

                if (!mainRegionalityIsSelected) {
                    delete value[DictionaryType.Division];
                }
            } else {
                delete value[DictionaryType.Direction];
                delete value[DictionaryType.Tool];
                delete value[DictionaryType.Resource];
                delete value[DictionaryType.Item];
            }
        } else {
            delete value[dictionaryToDelete];
        }

        return value;
    }
}
