/* tslint:disable:max-file-line-count */
import { combineReducers } from 'redux';
import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { DictionaryType } from '@mrm/dictionary';

import { MultiReferenceDictionaryApi } from '@api';

import {
    ACTIVITY_FIELDS_COLUMN_NAMES,
    ACTIVITY_MONTH_BALANCE_COLUMN_NAMES,
    // ACTIVITY_QUARTER_BALANCE_COLUMN_NAMES,
    BudgetExecutionPageState,
    CellValueType,
    ChangeCellValueParams,
    ChangeList,
    ColumnName,
    ColumnsVisiblityFilter,
    ColumnsWidth,
    CORRECTION_FILTER_TYPE,
    CorrectionPopup,
    CustomCellType,
    FACT_COLUMN_NAMES,
    Filters,
    GroupedCorrections,
    LineCorrectionTypes,
    LoadFiltersPaylaod,
    OrderType,
    PageData,
    PageState,
    RESERVED_COLUMN_NAMES,
    SetFiltersLoadingStatusPayload,
    SortingMode,
    UnsavedChange,
    UpdateLinesDataParams,
    ResetFilterPayload,
    AppliedFiltersNames,
    ActivityReferenceMenuVisibility,
    CreativeReferenceMenuVisibility,
    SetPreviouslyLoadedFilterPayload,
    SetPreviouslyLoadedFiltersPayload,
    ColumnsNameWithCodes,
    TableLinesCellsParams,
    PageFilters,
    TableLine,
    DropdownOptions,
    ColumnsWithSameData,
} from './types';

import * as actions from './actions';
import { ColumnsList, DictionaryColumns } from '../../modules/budget/BudgetPage/BudgetExecution/ColumnsConfig';
import { Utils } from '@common/Utils';
import { LinkedList } from './lib/LinkedList';

import { LoadingStatus } from '@store/commonTypes';

import { budgetTransferMenuReducer } from './budgetTransferMenu';
import { activityReferenceMenuReducer } from './activityReferenceMenu';
import { creativeReferenceMenuReducer } from './creativeReferenceMenu';
import { lineModalReducer } from './lineModal';
import { transferBudgetItemsToPlanningMenuReducer } from './transferBudgetItemsToPlanningMenu';
import { miscBudgetItemsReducer } from './miscBudgetItems';
import { importFMPTableMenuReducer } from './importFMPTableMenu';

import { ActivityBudget, BudgetItem, CorrectionType } from '@mrm/budget';
import { updateAppliedFiltersNamesUseAllFilters, updateColumnFilterLoadingStatus } from './utils';

const allItemsAreHiddenByDefault = ColumnsList.every((column) => column.hiddenByDefault);
const allItemsAreVisibleByDefault = ColumnsList.every((column) => !column.hiddenByDefault);

const columnsVisiblityFilterInitialState = ColumnsList.reduce((acc, item) => {
    acc[item.name] = allItemsAreHiddenByDefault || allItemsAreVisibleByDefault ? false : !item.hiddenByDefault;

    return acc;
}, {});

const sortingModeInitialState = {
    columnName: ColumnName.Id,
    order: OrderType.Asc,
};

const columnsWidthInitialState = ColumnsList.reduce((acc, item) => ({ ...acc, [item.name]: item.width }), {});

export function makeCorrectionsToDisplayInitialState() {
    return {
        [CORRECTION_FILTER_TYPE.ACTIVITY_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.BUDGET_ITEM_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.PLAN_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.RESERVE_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.NO_CORRECTIONS]: false,
    };
}

class Reducer {
    public static makeInitialState(): PageState {
        return {
            pageData: {
                activityBudgets: [],
                budget: null,
                budgetItems: [],
                budgetItemsToIgnoreFilters: [],
                userDictionaries: { byId: {}, byType: {} },
                allDictionaries: { byId: {}, byType: {} },
                users: [],
                allUsers: [],
                corrections: {
                    activityCorrections: {},
                    budgetItemCorrections: {},
                    planCorrections: {},
                    reserveCorrections: {},
                    factCorrections: {},
                    incomeExternalPlanCorrections: {},
                    outcomeExternalPlanCorrections: {},
                },
            },
            computedData: {
                pageBudgetItems: [],
                lineIdsWithActualChanges: [],
                tableLinesCellsParams: {},
                tableLines: [],
                dropdownOptions: {},
            },
            pageFilters: {
                columnsVisiblityFilter: columnsVisiblityFilterInitialState,
                sortingMode: sortingModeInitialState,
                filters: ColumnsList.reduce((acc, item) => {
                    acc[item.name] = {};
                    return acc;
                }, {}),
                appliedFiltersNames: [],
                validationStatus: false,
                showOnlyLinesWithoutPlan: false,
                showOnlyLinesWithNegativeBalance: false,
                useLinesWithoutPlanInSorting: false,
                correctionsToDisplay: makeCorrectionsToDisplayInitialState(),
                showOnlyLinesWithPlanBudget: false,
                showOnlyLinesWithNegativePlanFactDiff: false,
            },
            activityReferenceMenuVisibility: {
                visibility: false,
            },
            creativeReferenceMenuVisibility: {
                visibility: false,
            },
            columnsWidth: columnsWidthInitialState,
            resizingColumnName: null,
            hoveredLineId: null,
            hoveredColumnName: null,
            unsavedChanges: {},
            changesHistory: new LinkedList<UnsavedChange[]>(),
            currentChangePosition: 0,
            fixedColumnsNames: [],
            correctionPopup: {
                show: false,
            },
            columnFilters: {
                filters: {
                    [ColumnName.Comment]: {},
                    [ColumnName.Id]: {},
                    [ColumnName.DonorIds]: {},
                    [ColumnName.Regionality]: {},
                    [ColumnName.ActivityName]: {},
                    [ColumnName.Direction]: {},
                    [ColumnName.Tool]: {},
                    [ColumnName.SapComment]: {},
                    [ColumnName.Block]: {},
                    [ColumnName.Division]: {},
                    [ColumnName.CostCenter]: {},
                    [ColumnName.ActivityType]: {},
                    [ColumnName.Channel]: {},
                    [ColumnName.LocationDriver]: {},
                    [ColumnName.Item]: {},
                    [ColumnName.Resource]: {},
                    [ColumnName.Responsible]: {},
                    [ColumnName.Segment]: {},
                    [ColumnName.Product]: {},
                    [ColumnName.Territory]: {},
                    [ColumnName.StartDate]: {},
                    [ColumnName.EndDate]: {},
                    [ColumnName.LastYearFact]: {},
                    [ColumnName.PlanQuarter1]: {},
                    [ColumnName.PlanQuarter2]: {},
                    [ColumnName.PlanQuarter3]: {},
                    [ColumnName.PlanQuarter4]: {},
                    [ColumnName.ReserveQuarter1]: {},
                    [ColumnName.ReserveQuarter2]: {},
                    [ColumnName.ReserveQuarter3]: {},
                    [ColumnName.ReserveQuarter4]: {},
                    [ColumnName.FactQuarter1]: {},
                    [ColumnName.FactQuarter2]: {},
                    [ColumnName.FactQuarter3]: {},
                    [ColumnName.FactQuarter4]: {},
                    [ColumnName.PlanJan]: {},
                    [ColumnName.PlanFeb]: {},
                    [ColumnName.PlanMar]: {},
                    [ColumnName.PlanApr]: {},
                    [ColumnName.PlanMay]: {},
                    [ColumnName.PlanJun]: {},
                    [ColumnName.PlanJul]: {},
                    [ColumnName.PlanAug]: {},
                    [ColumnName.PlanSep]: {},
                    [ColumnName.PlanOct]: {},
                    [ColumnName.PlanNov]: {},
                    [ColumnName.PlanDec]: {},
                    [ColumnName.ReserveJan]: {},
                    [ColumnName.ReserveFeb]: {},
                    [ColumnName.ReserveMar]: {},
                    [ColumnName.ReserveApr]: {},
                    [ColumnName.ReserveMay]: {},
                    [ColumnName.ReserveJun]: {},
                    [ColumnName.ReserveJul]: {},
                    [ColumnName.ReserveAug]: {},
                    [ColumnName.ReserveSep]: {},
                    [ColumnName.ReserveOct]: {},
                    [ColumnName.ReserveNov]: {},
                    [ColumnName.ReserveDec]: {},
                    [ColumnName.FactJan]: {},
                    [ColumnName.FactFeb]: {},
                    [ColumnName.FactMar]: {},
                    [ColumnName.FactApr]: {},
                    [ColumnName.FactMay]: {},
                    [ColumnName.FactJun]: {},
                    [ColumnName.FactJul]: {},
                    [ColumnName.FactAug]: {},
                    [ColumnName.FactSep]: {},
                    [ColumnName.FactOct]: {},
                    [ColumnName.FactNov]: {},
                    [ColumnName.FactDec]: {},
                    [ColumnName.TotalPlan]: {},
                    [ColumnName.TotalReserve]: {},
                    [ColumnName.TotalFact]: {},
                    [ColumnName.SapZns]: {},
                    [ColumnName.SapCorrectionNumber]: {},
                    [ColumnName.Ifkv]: {},
                    [ColumnName.ResourceUsage]: {},
                    [ColumnName.CostDirecrtion]: {},
                    [ColumnName.Subcategory]: {},
                    [ColumnName.Objective]: {},
                    [ColumnName.ActivityBalanceJan]: {},
                    [ColumnName.ActivityBalanceFeb]: {},
                    [ColumnName.ActivityBalanceMar]: {},
                    [ColumnName.ActivityBalanceApr]: {},
                    [ColumnName.ActivityBalanceMay]: {},
                    [ColumnName.ActivityBalanceJun]: {},
                    [ColumnName.ActivityBalanceJul]: {},
                    [ColumnName.ActivityBalanceAug]: {},
                    [ColumnName.ActivityBalanceSep]: {},
                    [ColumnName.ActivityBalanceOct]: {},
                    [ColumnName.ActivityBalanceNov]: {},
                    [ColumnName.ActivityBalanceDec]: {},
                    [ColumnName.FactBalanceJan]: {},
                    [ColumnName.FactBalanceFeb]: {},
                    [ColumnName.FactBalanceMar]: {},
                    [ColumnName.FactBalanceApr]: {},
                    [ColumnName.FactBalanceMay]: {},
                    [ColumnName.FactBalanceJun]: {},
                    [ColumnName.FactBalanceJul]: {},
                    [ColumnName.FactBalanceAug]: {},
                    [ColumnName.FactBalanceSep]: {},
                    [ColumnName.FactBalanceOct]: {},
                    [ColumnName.FactBalanceNov]: {},
                    [ColumnName.FactBalanceDec]: {},
                    [ColumnName.ActivityBalanceYear]: {},
                    [ColumnName.FactBalanceYear]: {},
                    [ColumnName.Status]: {},
                    [ColumnName.DivisionCode]: {},
                    [ColumnName.RegionalityCode]: {},
                    [ColumnName.DirectionCode]: {},
                    [ColumnName.ToolCode]: {},
                    [ColumnName.BlockCode]: {},
                    [ColumnName.CostCenterCode]: {},
                    [ColumnName.ActivityTypeCode]: {},
                    [ColumnName.ChannelCode]: {},
                    [ColumnName.LocationDriverCode]: {},
                    [ColumnName.ResourceCode]: {},
                    [ColumnName.SegmentCode]: {},
                    [ColumnName.ProductCode]: {},
                    [ColumnName.BusinessGoal]: {},
                    [ColumnName.Customer]: {},
                    [ColumnName.Author]: {},
                    [ColumnName.TerritoryCode]: {},
                    [ColumnName.Tags]: {},
                    [ColumnName.TotalPlanQuarter1]: {},
                    [ColumnName.TotalPlanQuarter2]: {},
                    [ColumnName.TotalPlanQuarter3]: {},
                    [ColumnName.TotalPlanQuarter4]: {},
                    [ColumnName.TotalReserveQuarter1]: {},
                    [ColumnName.TotalReserveQuarter2]: {},
                    [ColumnName.TotalReserveQuarter3]: {},
                    [ColumnName.TotalReserveQuarter4]: {},
                    [ColumnName.TotalFactQuarter1]: {},
                    [ColumnName.TotalFactQuarter2]: {},
                    [ColumnName.TotalFactQuarter3]: {},
                    [ColumnName.TotalFactQuarter4]: {},
                    [ColumnName.TotalApprovedPlan]: {},
                },
                loadingStatus: {
                    [ColumnName.Comment]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Id]: LoadingStatus.NOT_LOADED,
                    [ColumnName.DonorIds]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Regionality]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityName]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Direction]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Tool]: LoadingStatus.NOT_LOADED,
                    [ColumnName.SapComment]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Block]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Division]: LoadingStatus.NOT_LOADED,
                    [ColumnName.CostCenter]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityType]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Channel]: LoadingStatus.NOT_LOADED,
                    [ColumnName.LocationDriver]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Item]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Resource]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Responsible]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Segment]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Product]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Territory]: LoadingStatus.NOT_LOADED,
                    [ColumnName.StartDate]: LoadingStatus.NOT_LOADED,
                    [ColumnName.EndDate]: LoadingStatus.NOT_LOADED,
                    [ColumnName.LastYearFact]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanJan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanFeb]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanMar]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanApr]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanMay]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanJun]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanJul]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanAug]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanSep]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanOct]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanNov]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanDec]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveJan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveFeb]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveMar]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveApr]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveMay]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveJun]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveJul]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveAug]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveSep]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveOct]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveNov]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ReserveDec]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactJan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactFeb]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactMar]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactApr]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactMay]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactJun]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactJul]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactAug]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactSep]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactOct]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactNov]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactDec]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalReserve]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalFact]: LoadingStatus.NOT_LOADED,
                    [ColumnName.SapZns]: LoadingStatus.NOT_LOADED,
                    [ColumnName.SapCorrectionNumber]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Ifkv]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ResourceUsage]: LoadingStatus.NOT_LOADED,
                    [ColumnName.CostDirecrtion]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Subcategory]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Objective]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceJan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceFeb]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceMar]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceApr]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceMay]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceJun]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceJul]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceAug]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceSep]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceOct]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceNov]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceDec]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceJan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceFeb]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceMar]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceApr]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceMay]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceJun]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceJul]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceAug]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceSep]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceOct]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceNov]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceDec]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityBalanceYear]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactBalanceYear]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Status]: LoadingStatus.NOT_LOADED,
                    [ColumnName.DivisionCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.RegionalityCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.DirectionCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ToolCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.BlockCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.CostCenterCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityTypeCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ChannelCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.LocationDriverCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ResourceCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.SegmentCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ProductCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.BusinessGoal]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Customer]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Author]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TerritoryCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Tags]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalReserveQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalReserveQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalReserveQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalReserveQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalFactQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalFactQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalFactQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalFactQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalApprovedPlan]: LoadingStatus.NOT_LOADED,
                },
            },
            loadingFiltersCount: 0,
            previouslyLoadedFilters: null,
            showTagsHaveChangedMarker: false,
            multiReferenceDictionaryApi: null,
            preventUserConfigUpdate: false,
        };
    }

    public static loadPageData(state: PageState, payload: Partial<PageData>): PageState {
        return {
            ...state,
            pageData: {
                ...state.pageData,
                ...payload,
            },
        };
    }

    public static loadBudgetItems(state: PageState, budgetItems: BudgetItem[]): PageState {
        return {
            ...state,
            pageData: {
                ...state.pageData,
                budgetItems,
            },
        };
    }

    public static loadActivityBudgets(state: PageState, activityBudgets: ActivityBudget[]): PageState {
        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
            },
        };
    }

    public static updateLinesData(state: PageState, payload: UpdateLinesDataParams): PageState {
        const activityBudgets = lodash.uniqBy(
            [...payload.activityBudgets, ...state.pageData.activityBudgets],
            (activityBudget) => activityBudget.id,
        );

        const budgetItems = lodash.uniqBy(
            [...payload.budgetItems, ...state.pageData.budgetItems],
            (budgetItem) => budgetItem.id,
        );

        const budgetItemsToIgnoreFilters = lodash.uniqBy(
            [...payload.budgetItemsToIgnoreFilters, ...state.pageData.budgetItemsToIgnoreFilters],
            (budgetItem) => budgetItem.id,
        );

        const corrections = {
            activityCorrections: mergeCorrections(
                state.pageData.corrections.activityCorrections,
                payload.corrections.activityCorrections,
            ),
            budgetItemCorrections: mergeCorrections(
                state.pageData.corrections.budgetItemCorrections,
                payload.corrections.budgetItemCorrections,
            ),
            planCorrections: mergeCorrections(
                state.pageData.corrections.planCorrections,
                payload.corrections.planCorrections,
            ),
            factCorrections: mergeCorrections(
                state.pageData.corrections.factCorrections,
                payload.corrections.factCorrections,
            ),
            reserveCorrections: mergeCorrections(
                state.pageData.corrections.reserveCorrections,
                payload.corrections.reserveCorrections,
            ),
            incomeExternalPlanCorrections: mergeCorrections(
                state.pageData.corrections.incomeExternalPlanCorrections,
                payload.corrections.incomeExternalPlanCorrections,
            ),
            outcomeExternalPlanCorrections: mergeCorrections(
                state.pageData.corrections.outcomeExternalPlanCorrections,
                payload.corrections.outcomeExternalPlanCorrections,
            ),
        };

        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
                budgetItems,
                budgetItemsToIgnoreFilters,
                corrections,
            },
        };
    }

    public static initUnsavedChanges(state: PageState, payload: ChangeList): PageState {
        return { ...state, unsavedChanges: { ...state.unsavedChanges, ...payload } };
    }

    public static setColumnsVisiblityFilter(state: PageState, payload: ColumnsVisiblityFilter): PageState {
        const columnsVisiblityFilter = lodash.keys(state.pageFilters.columnsVisiblityFilter).reduce(
            (acc, columnName) => ({
                ...acc,
                ...{
                    [columnName]: !lodash.isNil(payload[columnName])
                        ? payload[columnName]
                        : state.pageFilters.columnsVisiblityFilter[columnName],
                },
            }),
            {},
        );

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                columnsVisiblityFilter,
            },
        };
    }

    public static setColumnsWidth(state: PageState, payload: ColumnsWidth): PageState {
        // Preventing the case when column width is not in received userConfig
        // This can happen after applying old userConfig to table with new fields
        const updatedColumnsWidth = {};
        for (const columnName in columnsWidthInitialState) {
            updatedColumnsWidth[columnName] = payload[columnName] || columnsWidthInitialState[columnName];
        }

        return { ...state, columnsWidth: updatedColumnsWidth };
    }

    public static setResizingColumnName(state: PageState, payload: ColumnName): PageState {
        return { ...state, resizingColumnName: payload };
    }

    public static setHoveredColumnName(state: PageState, payload: ColumnName): PageState {
        return { ...state, hoveredColumnName: payload };
    }

    public static setSortingMode(state: PageState, payload: SortingMode): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, sortingMode: payload } };
    }

    public static setFilters(state: PageState, payload: Filters): PageState {
        const filters = {
            ...state.columnFilters.filters,
            ...payload,
        };

        const appliedFiltersNames = updateAppliedFiltersNamesUseAllFilters({
            filters,
            appliedFiltersNames: state.pageFilters.appliedFiltersNames,
        });

        // const loadingStatus = updateColumnFilterLoadingStatus({
        //     filtersGroup: {
        //         prev: state.columnFilters.filters,
        //         current: filters,
        //     },
        //     appliedFiltersNamesGroup: {
        //         prev: state.pageFilters.appliedFiltersNames,
        //         current: appliedFiltersNames,
        //     },
        //     columnFiltersLoadingStatus: state.columnFilters.loadingStatus,
        // });

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames,
            },
        };
    }

    public static setAppliedFiltersNames(state: PageState, payload: AppliedFiltersNames): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames: payload,
            },
        };
    }

    public static resetFilter(state: PageState, payload: ResetFilterPayload): PageState {
        const { filterName } = payload;

        const filters = {
            ...state.columnFilters.filters,
            [filterName]: lodash.keys(state.columnFilters.filters[filterName]).reduce((filter, filterName) => {
                return { ...filter, [filterName]: false };
            }, {}),
        };

        const appliedFiltersNames = updateAppliedFiltersNamesUseAllFilters({
            filters,
            appliedFiltersNames: state.pageFilters.appliedFiltersNames,
        });

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames,
            },
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    [filterName]: lodash.keys(state.columnFilters.filters[filterName]).reduce((filter, key) => {
                        return {
                            ...filter,
                            [key]: false,
                        };
                    }, {}),
                },
                loadingStatus: updateColumnFilterLoadingStatus({
                    filtersGroup: {
                        prev: state.columnFilters.filters,
                        current: filters,
                    },
                    appliedFiltersNamesGroup: {
                        prev: state.pageFilters.appliedFiltersNames,
                        current: appliedFiltersNames,
                    },
                    columnFiltersLoadingStatus: state.columnFilters.loadingStatus,
                }),
            },
        };
    }

    public static resetFilters(state: PageState): PageState {
        const cleanUpdatedFilters = lodash.cloneDeep(state.columnFilters.filters);
        const cleanUpdatedFiltersLoadingStatus = lodash.cloneDeep(state.columnFilters.loadingStatus);

        lodash.forEach(cleanUpdatedFilters, (columnFilters, columnName) => {
            lodash.forEach(columnFilters, (value, title) => {
                cleanUpdatedFilters[columnName][title] = false;
            });
        });

        lodash.forEach(
            cleanUpdatedFiltersLoadingStatus,
            (columnFilters, columnName) => (cleanUpdatedFiltersLoadingStatus[columnName] = LoadingStatus.NOT_LOADED),
        );

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames: [],
                correctionsToDisplay: makeCorrectionsToDisplayInitialState(),
                showOnlyLinesWithoutPlan: false,
                showOnlyLinesWithNegativeBalance: false,
            },
            columnFilters: {
                filters: cleanUpdatedFilters,
                loadingStatus: cleanUpdatedFiltersLoadingStatus,
            },
        };
    }

    public static resetViewSettings(state: PageState): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                sortingMode: sortingModeInitialState,
                columnsVisiblityFilter: columnsVisiblityFilterInitialState,
            },
            fixedColumnsNames: [],
            columnsWidth: columnsWidthInitialState,
        };
    }

    public static setValidationStatus(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                validationStatus: payload,
            },
        };
    }

    public static toggleColumnFix(state: PageState, payload: ColumnName): PageState {
        return {
            ...state,
            fixedColumnsNames: lodash.xor(state.fixedColumnsNames, [payload]),
        };
    }

    public static setFixedColumnsNames(state: PageState, payload: ColumnName[]): PageState {
        return {
            ...state,
            fixedColumnsNames: payload,
        };
    }

    public static setActivityReferenceMenuVisibility(
        state: PageState,
        payload: ActivityReferenceMenuVisibility,
    ): PageState {
        return {
            ...state,
            activityReferenceMenuVisibility: payload,
        };
    }

    public static setCreativeReferenceMenuVisibility(
        state: PageState,
        payload: CreativeReferenceMenuVisibility,
    ): PageState {
        return {
            ...state,
            creativeReferenceMenuVisibility: payload,
        };
    }

    public static changeCellValueDone(
        state: PageState,
        payload: Success<ChangeCellValueParams, ChangeCellValueParams>,
    ): PageState {
        const { change, line, generateSameValueChange, tags } = payload.result;
        const { pageData, unsavedChanges, changesHistory, currentChangePosition, multiReferenceDictionaryApi } = state;
        const { activityBudgets, budgetItems, budgetItemsToIgnoreFilters, userDictionaries, users } = pageData;

        const budgetItemsToUse = [...budgetItems, ...budgetItemsToIgnoreFilters];
        const budgetItem = budgetItemsToUse.find((item) => item.id == change.budgetItemId);
        const column = ColumnsList.find((item) => item.name == change.columnName);
        const dictionaryType = lodash.get(column, 'metaData.dictionaryType') as DictionaryType;

        const newChange = validateNewChange(change);
        let changes: UnsavedChange[] = [];

        if (ColumnsWithSameData[newChange.columnName]) {
            changes = ColumnsWithSameData[newChange.columnName].map((column: ColumnName) => ({
                ...newChange,
                columnName: column,
            }));
        } else {
            changes = [newChange];
        }

        if (dictionaryType) {
            const lineDictionaryValue: Partial<Record<DictionaryType, string>> = {};
            DictionaryColumns.forEach((dictionaryColumn) => {
                if (!ColumnsNameWithCodes.includes(dictionaryColumn.name)) {
                    const columnChange = unsavedChanges[line.id]?.find(
                        (change) => change.columnName === dictionaryColumn.name,
                    );
                    const valueToUse = (
                        columnChange ? columnChange.value : line.fields[dictionaryColumn.name]
                    ) as string;

                    if (valueToUse && userDictionaries.byId[valueToUse]) {
                        lineDictionaryValue[dictionaryColumn.metaData.dictionaryType] =
                            userDictionaries.byId[valueToUse].id;
                    }
                }
            });

            const updatedValue = multiReferenceDictionaryApi.performDictionaryUpdate(
                Object.values(lineDictionaryValue),
                dictionaryType,
                (newChange.value || null) as string,
            );

            const keysToCheck = lodash.uniq([
                ...Object.keys(lineDictionaryValue),
                ...Object.keys(updatedValue),
            ]) as DictionaryType[];

            keysToCheck.forEach((updatedValueKey: DictionaryType) => {
                const changeColumn = DictionaryColumns.find(
                    (column) => column.metaData.dictionaryType === updatedValueKey,
                );

                if (changeColumn) {
                    const value = updatedValue[updatedValueKey]?.id || null;
                    const originalValue = changeColumn.accessor({
                        budgetItem,
                        activityBudgets,
                        users,
                        tags,
                    }) as string;

                    let shouldAddChange;
                    if (
                        updatedValueKey === DictionaryType.Regionality &&
                        change?.columnName !== ColumnName.Regionality &&
                        value &&
                        originalValue
                    ) {
                        shouldAddChange = false;
                    } else if (change.columnName === changeColumn.name) {
                        shouldAddChange = true;
                    } else {
                        const valueAppearedOrDisappearedAfterMutation = lodash.xor([
                            column.metaData.dictionaryType in lineDictionaryValue,
                            column.metaData.dictionaryType in updatedValue,
                        ]);

                        shouldAddChange = valueAppearedOrDisappearedAfterMutation;
                    }

                    if (shouldAddChange) {
                        changes.push({
                            budgetItemId: newChange.budgetItemId,
                            columnName: changeColumn.name,
                            value,
                            originalValue,
                        });
                    }
                }
            });
        }

        const activityBudgetFieldChanged = lodash.includes(ACTIVITY_FIELDS_COLUMN_NAMES, column.name);

        if (activityBudgetFieldChanged) {
            const activityNameChange = changes[0];

            const activityId = budgetItem.activity.id;
            const activityBudgetItems = budgetItemsToUse.filter((item) => item.activity.id == activityId);

            activityBudgetItems.forEach((budgetItem) => {
                if (activityNameChange.budgetItemId !== budgetItem.id) {
                    changes.push({ ...activityNameChange, budgetItemId: budgetItem.id });
                }
            });
        }

        if (currentChangePosition < changesHistory.length) {
            changesHistory.remove(currentChangePosition + 1);
        }

        changesHistory.add(changes);

        const updatedChangesList = lodash.clone(unsavedChanges);

        applyChangesToChangeList(updatedChangesList, changes, generateSameValueChange);

        return {
            ...state,
            unsavedChanges: updatedChangesList,
            currentChangePosition: changesHistory.length,
        };
    }

    public static undoUnsavedChanges(state: PageState): PageState {
        const { unsavedChanges, changesHistory, currentChangePosition } = state;

        if (currentChangePosition < 1) {
            return state;
        }

        const newChangePosition = currentChangePosition - 1;

        const changesToUndo = changesHistory.getValueAtPosition(currentChangePosition);
        const changesToRestore = findLastChangesAtSameCells(newChangePosition, changesToUndo);

        let updatedChangeList = lodash.clone(unsavedChanges);

        updatedChangeList = removeChangesFromChangeList(updatedChangeList, changesToUndo);
        updatedChangeList = applyChangesToChangeList(updatedChangeList, changesToRestore);

        return {
            ...state,
            unsavedChanges: updatedChangeList,
            currentChangePosition: newChangePosition,
        };

        function findLastChangesAtSameCells(startPosition: number, changes: UnsavedChange[]): UnsavedChange[] {
            if (startPosition < 1) {
                return [];
            }

            let currentPosition = startPosition;

            let currentChanges: UnsavedChange[] = changesHistory.getValueAtPosition(currentPosition);

            while (!changesAreAtSameCells(currentChanges, changes) && currentPosition > 0) {
                currentPosition--;

                if (currentPosition > 0) {
                    currentChanges = changesHistory.getValueAtPosition(currentPosition);
                }
            }

            return currentPosition > 0 ? currentChanges : [];

            function changesAreAtSameCells(changesA: UnsavedChange[], changesB: UnsavedChange[]): boolean {
                return changesA.every((changeA) =>
                    changesB.some(
                        (changeB) =>
                            changeA.budgetItemId == changeB.budgetItemId && changeA.columnName == changeB.columnName,
                    ),
                );
            }
        }
    }

    public static redoUnsavedChanges(state: PageState): PageState {
        const { unsavedChanges, changesHistory, currentChangePosition } = state;

        const newChangePosition = currentChangePosition + 1;

        if (newChangePosition > changesHistory.length) {
            return state;
        }

        const changesToRestore = changesHistory.getValueAtPosition(newChangePosition);

        let updatedChangeList = lodash.clone(unsavedChanges);

        updatedChangeList = applyChangesToChangeList(updatedChangeList, changesToRestore);

        return {
            ...state,
            unsavedChanges: updatedChangeList,
            currentChangePosition: newChangePosition,
        };
    }

    public static clearUnsavedChanges(state: PageState): PageState {
        const { budgetItems, budgetItemsToIgnoreFilters } = state.pageData;

        const lineIds = [...budgetItems, ...budgetItemsToIgnoreFilters].map((item) => item.id);

        const initialChangesList = {};

        lineIds.forEach((lineId) => {
            initialChangesList[lineId] = [];
        });

        return { ...state, unsavedChanges: initialChangesList };
    }

    public static clearUnsavedChangesByLines(state: PageState, payload: string[]): PageState {
        const { budgetItems, budgetItemsToIgnoreFilters } = state.pageData;
        const budgetItemsToUse = [...budgetItems, ...budgetItemsToIgnoreFilters];

        const updatedChangeList = lodash.clone(state.unsavedChanges);

        const lineIds: string[] = [];

        payload.forEach((lineId) => {
            const budgetItem = budgetItemsToUse.find((item) => item.id == lineId);

            const activityLinesIds = budgetItemsToUse
                .filter((item) => updatedChangeList[item.id] && item.activity.id == budgetItem.activity.id)
                .map((item) => item.id);

            lineIds.push(...activityLinesIds);
        });

        lineIds.forEach((lineId) => {
            const updatedLineChanges = updatedChangeList[lineId].filter(
                (change) =>
                    !lodash.includes(
                        [
                            ...ACTIVITY_FIELDS_COLUMN_NAMES,
                            ...ACTIVITY_MONTH_BALANCE_COLUMN_NAMES,
                            // ...ACTIVITY_QUARTER_BALANCE_COLUMN_NAMES,
                        ],
                        change.columnName,
                    ),
            );

            updatedChangeList[lineId] = updatedLineChanges;
        });

        payload.forEach((lineId) => {
            updatedChangeList[lineId] = [];
        });

        return { ...state, unsavedChanges: updatedChangeList };
    }

    public static resetChangesHistory(state: PageState): PageState {
        return {
            ...state,
            changesHistory: new LinkedList<UnsavedChange[]>(),
            currentChangePosition: 0,
        };
    }

    public static setShowOnlyLinesWithoutPlan(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                showOnlyLinesWithoutPlan: payload,
            },
        };
    }

    public static setShowOnlyLinesWithNegativeBalance(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                showOnlyLinesWithNegativeBalance: payload,
            },
        };
    }

    public static setUseLinesWithoutPlanInSorting(state: PageState, useLinesWithoutPlanInSorting: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, useLinesWithoutPlanInSorting },
        };
    }

    public static setShowOnlyLinesWithPlanBudget(state: PageState, showOnlyLinesWithPlanBudget: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, showOnlyLinesWithPlanBudget },
        };
    }

    public static setShowOnlyLinesWithNegativePlanFactDiff(
        state: PageState,
        showOnlyLinesWithNegativePlanFactDiff: boolean,
    ): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, showOnlyLinesWithNegativePlanFactDiff },
        };
    }

    public static setCorrectionsToDisplay(state: PageState, payload: LineCorrectionTypes): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                correctionsToDisplay: payload,
            },
        };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static setCorrectionPopup(state: PageState, payload: CorrectionPopup): PageState {
        return {
            ...state,
            correctionPopup: {
                ...payload,
            },
        };
    }

    public static loadFilters(state: PageState, payload: LoadFiltersPaylaod): PageState {
        const { columnName, filters } = payload;

        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    [columnName]: filters,
                },
            },
        };
    }

    public static setFiltersLoadingStatus(state: PageState, payload: SetFiltersLoadingStatusPayload): PageState {
        const { columnName, loadingStatus } = payload;

        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                loadingStatus: {
                    ...state.columnFilters.loadingStatus,
                    [columnName]: loadingStatus,
                },
            },
        };
    }

    public static resetBudgetRelatedData(state: PageState): PageState {
        const {
            pageData: { activityBudgets, budgetItems, corrections, budgetItemsToIgnoreFilters },
            columnFilters,
        } = Reducer.makeInitialState();

        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
                budgetItems,
                corrections,
                budgetItemsToIgnoreFilters,
            },
            columnFilters,
        };
    }

    public static initPreviouslyLoadedFilters(state: PageState): PageState {
        return {
            ...state,
            previouslyLoadedFilters: state.columnFilters.filters,
        };
    }

    public static setPreviouslyLoadedFilter(state: PageState, payload: SetPreviouslyLoadedFilterPayload): PageState {
        const { columnName, filter } = payload;

        return {
            ...state,
            previouslyLoadedFilters: {
                ...state.previouslyLoadedFilters,
                [columnName]: filter,
            },
        };
    }

    public static setPreviouslyLoadedFilters(state: PageState, payload: SetPreviouslyLoadedFiltersPayload): PageState {
        const { filters } = payload;

        return {
            ...state,
            previouslyLoadedFilters: {
                ...state.previouslyLoadedFilters,
                ...filters,
            },
        };
    }

    public static resetPreviouslyLoadedFilters(state: PageState): PageState {
        return {
            ...state,
            previouslyLoadedFilters: state.columnFilters.filters,
        };
    }

    public static setHoveredLineId(state: PageState, hoveredLineId: string): PageState {
        return { ...state, hoveredLineId };
    }

    public static setShowTagsHaveChangedMarker(state: PageState, showTagsHaveChangedMarker: boolean): PageState {
        return { ...state, showTagsHaveChangedMarker };
    }

    public static setMultiReferenceDictionaryApi(
        state: PageState,
        multiReferenceDictionaryApi: MultiReferenceDictionaryApi,
    ): PageState {
        return { ...state, multiReferenceDictionaryApi };
    }

    public static setTableLinesCellsParams(state: PageState, tableLinesCellsParams: TableLinesCellsParams): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, tableLinesCellsParams },
        };
    }

    public static setPageBudgetItems(state: PageState, pageBudgetItems: BudgetItem[]): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, pageBudgetItems },
        };
    }

    public static setFiltersState(state: PageState, filters: Filters): PageState {
        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    ...filters,
                },
            },
        };
    }

    public static setPageFilters(state: PageState, pageFilters: Partial<PageFilters>): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, ...pageFilters },
        };
    }

    public static setLineIdsWithActualChanges(state: PageState, lineIdsWithActualChanges: string[]): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, lineIdsWithActualChanges },
        };
    }

    public static resetLoadedFilters(state: PageState): PageState {
        const {
            columnFilters: { loadingStatus },
        } = Reducer.makeInitialState();

        return {
            ...state,
            columnFilters: { ...state.columnFilters, loadingStatus },
        };
    }

    public static setTableLines(state: PageState, tableLines: TableLine[]): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, tableLines },
        };
    }

    public static setDropdownOptions(state: PageState, dropdownOptions: DropdownOptions): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, dropdownOptions },
        };
    }

    public static setPreventUserConfigUpdate(state: PageState, preventUserConfigUpdate: boolean): PageState {
        return { ...state, preventUserConfigUpdate };
    }
}

function validateNewChange(newChange: UnsavedChange): UnsavedChange {
    const column = ColumnsList.find((item) => item.name == newChange.columnName);

    const updatedNewChange = lodash.clone(newChange);

    const isPreviousFundsCell = newChange.columnName == ColumnName.LastYearFact;

    if (isPreviousFundsCell && updatedNewChange.value) {
        const [intPart, fractionalPart] = (updatedNewChange.value as string).split('.');

        if (fractionalPart) {
            updatedNewChange.value = `${intPart}.${fractionalPart.slice(0, 2)}`;
        }
    }

    if (
        column.customCellType == CustomCellType.Input &&
        column.valueType == CellValueType.String &&
        lodash.get(column, 'metaData.maxLength') !== undefined &&
        updatedNewChange.value
    ) {
        updatedNewChange.value = (updatedNewChange.value as string).slice(0, column.metaData.maxLength);
    }

    return updatedNewChange;
}

export function applyChangesToChangeList(
    changeList: ChangeList,
    changes: UnsavedChange[],
    generateSameValueChange?: boolean,
): ChangeList {
    changes.forEach((change) => {
        const changeColumn = ColumnsList.find((column) => column.name === change.columnName);
        const lineId = change.budgetItemId;

        const lineChanges = changeList[lineId] || [];

        const oldChange = lineChanges.find((item) => item.columnName == change.columnName);

        const newLineChanges = lodash.without(lineChanges, oldChange);

        let valueHaveChanged = change.value !== change.originalValue;
        if (changeColumn?.valueType === CellValueType.Currency) {
            const formulaValueBefore = change.originalValue
                ? Utils.calculateCurrencyFormula(change.originalValue as string)
                : 0;
            const formulaValueAfter = change.value ? Utils.calculateCurrencyFormula(change.value as string) : 0;

            valueHaveChanged = formulaValueAfter !== formulaValueBefore;
        }

        if (valueHaveChanged || generateSameValueChange) {
            newLineChanges.push(change);
        }

        changeList[lineId] = newLineChanges;
    });

    return changeList;
}

function removeChangesFromChangeList(changeList: ChangeList, changes: UnsavedChange[]): ChangeList {
    changes.forEach((item) => {
        changeList[item.budgetItemId] = lodash.without(changeList[item.budgetItemId], item);
    });

    return changeList;
}

function mergeCorrections<T extends CorrectionType>(
    currentCorrections: GroupedCorrections<T>,
    newCorrections: GroupedCorrections<T>,
): GroupedCorrections<T> {
    const updatedCorrections = lodash.clone(currentCorrections);

    lodash.forEach(newCorrections, (item, key) => {
        updatedCorrections[key] = item;
    });

    return updatedCorrections;
}

const budgetExecutionPageStateReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.updateLinesData, Reducer.updateLinesData)
    .case(actions.initUnsavedChanges, Reducer.initUnsavedChanges)
    .case(actions.resetPageStore, Reducer.makeInitialState)
    .case(actions.setColumnsVisiblityFilter, Reducer.setColumnsVisiblityFilter)
    .case(actions.setColumnsWidth, Reducer.setColumnsWidth)
    .case(actions.setResizingColumnName, Reducer.setResizingColumnName)
    .case(actions.setHoveredColumnName, Reducer.setHoveredColumnName)
    .case(actions.setSortingMode, Reducer.setSortingMode)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.resetFilter, Reducer.resetFilter)
    .case(actions.resetFilters, Reducer.resetFilters)
    .case(actions.resetViewSettings, Reducer.resetViewSettings)
    .case(actions.setValidationStatus, Reducer.setValidationStatus)
    .case(actions.toggleColumnFix, Reducer.toggleColumnFix)
    .case(actions.setFixedColumnsNames, Reducer.setFixedColumnsNames)
    .case(actions.setActivityReferenceMenuVisibility, Reducer.setActivityReferenceMenuVisibility)
    .case(actions.setCreativeReferenceMenuVisibility, Reducer.setCreativeReferenceMenuVisibility)
    .case(actions.changeCellValueAsync.done, Reducer.changeCellValueDone)
    .case(actions.undoUnsavedChanges, Reducer.undoUnsavedChanges)
    .case(actions.redoUnsavedChanges, Reducer.redoUnsavedChanges)
    .case(actions.clearUnsavedChanges, Reducer.clearUnsavedChanges)
    .case(actions.clearUnsavedChangesByLines, Reducer.clearUnsavedChangesByLines)
    .case(actions.resetChangesHistory, Reducer.resetChangesHistory)
    .case(actions.setShowOnlyLinesWithoutPlan, Reducer.setShowOnlyLinesWithoutPlan)
    .case(actions.setShowOnlyLinesWithNegativeBalance, Reducer.setShowOnlyLinesWithNegativeBalance)
    .case(actions.setUseLinesWithoutPlanInSorting, Reducer.setUseLinesWithoutPlanInSorting)
    .case(actions.setShowOnlyLinesWithPlanBudget, Reducer.setShowOnlyLinesWithPlanBudget)
    .case(actions.setShowOnlyLinesWithNegativePlanFactDiff, Reducer.setShowOnlyLinesWithNegativePlanFactDiff)
    .case(actions.setCorrectionsToDisplay, Reducer.setCorrectionsToDisplay)
    .case(actions.setCorrectionPopup, Reducer.setCorrectionPopup)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.loadFilters, Reducer.loadFilters)
    .case(actions.loadBudgetItems, Reducer.loadBudgetItems)
    .case(actions.loadActivityBudgets, Reducer.loadActivityBudgets)
    .case(actions.setFiltersLoadingStatus, Reducer.setFiltersLoadingStatus)
    .case(actions.resetBudgetRelatedData, Reducer.resetBudgetRelatedData)
    .case(actions.initPreviouslyLoadedFilter, Reducer.initPreviouslyLoadedFilters)
    .case(actions.setPreviouslyLoadedFilter, Reducer.setPreviouslyLoadedFilter)
    .case(actions.setPreviouslyLoadedFilters, Reducer.setPreviouslyLoadedFilters)
    .case(actions.resetPreviouslyLoadedFilters, Reducer.resetPreviouslyLoadedFilters)
    .case(actions.setAppliedFiltersNames, Reducer.setAppliedFiltersNames)
    .case(actions.setHoveredLineId, Reducer.setHoveredLineId)
    .case(actions.setShowTagsHaveChangedMarker, Reducer.setShowTagsHaveChangedMarker)
    .case(actions.setPageBudgetItems, Reducer.setPageBudgetItems)
    .case(actions.setMultiReferenceDictionaryApi, Reducer.setMultiReferenceDictionaryApi)
    .case(actions.setTableLinesCellsParams, Reducer.setTableLinesCellsParams)
    .case(actions.setFiltersState, Reducer.setFiltersState)
    .case(actions.setPageFilters, Reducer.setPageFilters)
    .case(actions.setLineIdsWithActualChanges, Reducer.setLineIdsWithActualChanges)
    .case(actions.resetLoadedFilters, Reducer.resetLoadedFilters)
    .case(actions.setTableLines, Reducer.setTableLines)
    .case(actions.setDropdownOptions, Reducer.setDropdownOptions)
    .case(actions.setPreventUserConfigUpdate, Reducer.setPreventUserConfigUpdate);

export const budgetExecutionPageReducer = combineReducers<BudgetExecutionPageState>({
    budgetTransferMenuState: budgetTransferMenuReducer,
    lineModal: lineModalReducer,
    transferBudgetItemsToPlannningMenu: transferBudgetItemsToPlanningMenuReducer,
    miscBudgetItems: miscBudgetItemsReducer,
    activityReferenceMenu: activityReferenceMenuReducer,
    creativeReferenceMenu: creativeReferenceMenuReducer,
    importFMPTableMenu: importFMPTableMenuReducer,
    restState: budgetExecutionPageStateReducer,
});
