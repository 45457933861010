import { Icons } from '@sbermarketing/mrm-ui';

import { Module } from 'sber-marketing-types/backend';

import { OnboardingId } from '@modules/onboarding/constants/onboardingIds';

export interface OnboardingItem {
    id: OnboardingId;
    icon: Icons;
    title: string;
    url: string;
    button: string;
    useFrom?: boolean;
}

export interface OnboardingGroup {
    icon: Icons;
    title: string;
    description: string;
    sections: OnboardingItem[];
    module?: Module;
}

export const onboardingItems: OnboardingGroup[] = [
    {
        title: 'Модуль проектов',
        description: 'Совместная работа над задачами',
        icon: 'ProjectIcon',
        sections: [
            {
                id: 'newActivity',
                title: 'Создать проект',
                icon: 'AddIcon',
                url: '/activity/new',
                button: 'СОЗДАТЬ',
                useFrom: true,
            },
            {
                id: 'searchActivity',
                title: 'Найти проект (задачу, комментарий или файл)',
                icon: 'SearchIcon',
                url: '/dashboard',
                button: 'НАЙТИ',
            },
        ],
    },
    {
        module: Module.Budget,
        title: 'Бюджетирование',
        description: 'Исполнение',
        icon: 'BudgetIcon',
        sections: [
            {
                id: 'newBudget',
                title: 'Создать строку бюджета',
                icon: 'AddIcon',
                url: '/budget/execution/new',
                button: 'СОЗДАТЬ',
                useFrom: true,
            },
            {
                id: 'searchBudget',
                title: 'Найти строку бюджета',
                icon: 'SearchIcon',
                url: '/budget/execution',
                button: 'НАЙТИ',
            },
        ],
    },
];
