import { FieldFile, FileDescription, FileEntity } from '@store/autopilot/creativeForm/types';
import { FileStorage, TypeFileValue } from '../../../../../../../../../../frontend-types/backend';
import { difference, includes, uniqBy } from 'lodash';
import { AutopilotApi, FileApi, SelcdnService } from '@api';
import { FileAssetTarget } from '@store/commonTypes';

export const handleFiles = async (
    currentValueFiles: FieldFile[],
    previousValueFiles: FieldFile[],
    autopilotId: string,
    fieldId: string,
): Promise<TypeFileValue[]> => {
    const currentValueFileIds = currentValueFiles.map(({ file }) => file?.description?.id);
    const previousValueFileIds = previousValueFiles.map(({ file }) => file?.description?.id);

    const onUploadFileIds = difference(currentValueFileIds, previousValueFileIds);
    const onRemoveFileIds = difference(previousValueFileIds, currentValueFileIds);

    const uploadedFiles: FieldFile[] = (
        await Promise.all(
            currentValueFiles
                .filter(({ file }) => includes(onUploadFileIds, file.description.id))
                .map(({ file }) => uploadFile(file.entity, file.description.id, autopilotId, fieldId)),
        )
    ).map(({ fileId, originalFilename, size, contentType, downloadLink }) => ({
        id: fileId,
        file: {
            description: {
                id: fileId,
                name: originalFilename,
                size: size,
                type: contentType,
                downloadLink,
            },
            entity: null,
        },
        error: '',
    }));

    try {
        await Promise.all(
            previousValueFiles
                .filter(({ file }) => includes(onRemoveFileIds, file.description.id))
                .map(({ file }) => removeFile(file.description, autopilotId)),
        );
    } catch (error) {
        console.warn(error);
    }

    return uniqBy([...uploadedFiles, ...currentValueFiles], 'file.description.id').map(({ file }) => ({
        fieldId,
        fileId: file.description.id,
        contentType: file.description.type,
        originalFilename: file.description.name,
        size: file.description.size,
        downloadLink: file.description.downloadLink,
    }));
};

const uploadFile = async (
    file: FileEntity,
    fileId: string,
    autopilotId: string,
    fieldId: string,
): Promise<TypeFileValue> => {
    const service = new SelcdnService();

    await service.upload(
        {
            autopilotId,
            fileId,
            originName: file.name,
            type: file.type,
        },
        {
            id: fileId,
            name: file.name,
            originName: file.name,
            createdAt: Date.now(),
            type: file.type,
            targetType: FileAssetTarget.AUTOPILOT,
            targetId: fileId,
            size: file.size,
            file: file,
        },
        {
            storageEngine: FileStorage.SEL_CDN,
            containerName: 'autopilot',
            fragmentSize: file.size,
            fileName: fileId,
        },
    );

    const downloadLink = await service.getDownloadLink(
        {
            autopilotId,
            fileId,
            originName: file.name,
            type: file.type,
        },
        {
            storageEngine: FileStorage.SEL_CDN,
            containerName: 'autopilot',
            originName: file.name,
            fileName: fileId,
        },
    );

    await AutopilotApi.addCreativeFile(autopilotId, fileId, {
        fileId,
        fieldId,
        name: file.name,
        originName: file.name,
        type: file.type,
        size: file.size,
        storage: FileStorage.SEL_CDN,
    });

    return {
        fileId,
        fieldId,
        originalFilename: file.name,
        contentType: file.type,
        size: file.size,
        downloadLink,
    };
};

const removeFile = async (fileDescription: FileDescription, autopilotId: string): Promise<void> => {
    await FileApi.removeFile(
        {
            autopilotId,
            fileId: fileDescription.id,
            originName: fileDescription.name,
            type: fileDescription.type,
        },
        fileDescription.name,
    );
};
